body {
  font-family: "Roboto", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif;
  background-color: #FCFCFC;
}

h1 { font-size: 2.1rem; }
h2 { font-size: 1.9rem; }
h3 { font-size: 1.7rem; }
h4,
h5,
h6 { font-size: 1.5rem; }

img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
  border: 0;
}

header,
footer {
  margin: 4rem 0;
}

main {
  margin: 4rem 0;
}

.container {
  width: 90%;
  max-width: 600px;
}

.header__navbar {
  display: flex;
  list-style: none;

  li {
    margin: 0 3px;
    &:first-child {
      margin-left: 0;
    }
  }
}

.site-title {
  margin-top: 2rem;
}

h1.article__title {
  font-size: 3.0rem;
}

.entry__title,
.article__title {
  margin-bottom: 0;
  a {
    text-decoration: none;
  }
}

.entry__meta {
  display: inline-block;
  margin-bottom: 5px;
  font-size: 1.7rem;
  color: #888;
}

.article__meta {
  display: inline-block;
  margin-bottom: 2rem;
  font-size: 1.7rem;
  color: #888;
}

.entry-meta__tags,
.article__tags {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0;
  li {
    list-style: none;
    margin: 0 3px;
    &:first-child {
      margin-left: 0;
    }
  }
}

.footer__links {
  margin: 2rem 0;
  display: flex;

  li {
    list-style: none;
    margin-right: 20px;
  }
}

.tag__meta {
  li {
    list-style: none;
    &:first-child {
      margin-left: 0;
    }
  }
}

.hr {
  height: 1px;
  margin: 2rem 0;
  background: #E1E1E1;
  background: -webkit-gradient(linear, left top, right top, from(white), color-stop(#E1E1E1), to(white));
  background: -webkit-linear-gradient(left, white, #E1E1E1, white);
  background: linear-gradient(to right, white, #E1E1E1, white);
}
